// src/aws-config.js
const apiConfig = {
    aws_cloud_logic_custom: [
      {
        name: 'designsigner',
        endpoint: 'https://57so8nopu1.execute-api.us-east-1.amazonaws.com/dev',
        region: 'us-east-1', // Replace with your AWS region,
        paths: {
            genkey: '/api/genkey',
            getkeys: '/api/getkeys',
            signfile: '/api/signfile',
            signstatus: '/api/signstatus',
            getfiles: '/api/getfiles',
            verfiyfile: '/api/verifyfile',
            verfiystatus: '/api/verifystatus'
          },
      },
    ],
    "aws_cognito_social_providers": [
      "GOOGLE","FACEBOOK"
    ],
  };
  
  export default apiConfig;
  